* {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji";
  font-weight: 400;
  line-height: 1.5;
}

h1 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
}

h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
}

h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
}

h4 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
}
h5 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
}
h6 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  background-color: #181a20;
}
